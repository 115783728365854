<template>
  <div v-if="data" class="flix-container">
    <h2 class="flix-html-h3">{{ $tc("message.clientChat", data.length) }}</h2>

    <div class="flix-form-group" :key="updateKey">
      <a v-if="unread.length" href="#" @click.prevent="function () {open = unread; show = 'unread'}" class="flix-btn" :class="{'flix-btn-default': show !== 'unread', 'flix-btn-info': show === 'unread'}" ><flixIcon id="warning-sign" /> {{ $t('message.unreadChats') }} <span class="flix-badge">{{ unread.length }}</span></a>
      <span v-for="(c, index) in calendar" :key="index">
        <a href="#" @click.prevent="function () { open = calendar[index]; show = index }" class="flix-btn" :class="{'flix-btn-default': show !== index, 'flix-btn-info': show === index}">{{ c[0].calendar.title }} <span class="flix-badge">{{ c.length }}</span></a>
      </span>
    </div>
    <div class="flix-form-group" v-if="open">
      <div class="flix-row">
        <openChats :data="open" :callback="callback" />
      </div>
    </div>
    <hr class="flix-html-hr" style="margin-top: 0"/>
    <div class="flix-form-group">
      <groupMessage />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    groupMessage () { return import('@/components/messages/calendarMessage') },
    openChats () { return import('@/components/messages/userchats') }
  },
  props: { data: Array, callback: Function },
  data () {
    return {
      unread: [],
      calendar: {},
      open: false,
      show: false,
      updateKey: 0
    }
  },
  mounted () {
    this.getOrder()
  },
  methods: {
    getOrder () {
      JSON.parse(JSON.stringify(this.data)).forEach(function (val) {
        val.calendar = val.chat_nr.split('/')
        val.calendar = val.calendar[1]
        val.calendar = this.$store.getters.assistentsByID[val.calendar]
        val.view2 = JSON.parse(val.view)
        if (val.view2.indexOf('bot') === -1) {
          this.unread.push(val)
        }

        if (typeof this.calendar[val.calendar.ID] === 'undefined') {
          this.calendar[val.calendar.ID] = []
        }
        this.calendar[val.calendar.ID].push(val)
        this.updateKey = new Date().getTime()
      }.bind(this))
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-badge
    display: inline-block
</style>
